import './App.scss';
import background from'./img/mountains.jpg';


function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <header>
        <h1 className="title">Scott Alexander Robertson</h1>
        </header>
    </div>
  );
}
export default App;